import 'swiper/css/bundle';
import '../stylesheets/application.sass';
import jQuery from 'jquery';
import Swiper from 'swiper/bundle';

const $ = jQuery;

$(function () {
  $(window).resize(function () {
    let width = $(document).width();
    if (width + 15 > 1024) {
      $('.l-header-copyright, .sub-menu').hide();
    }
  });

  $('#page-scroll-top').on('click', function () {
    $('html, body').animate({scrollTop: 0}, 500);
    return false;
  });

  function slideVisibleToggle(sWrap) {
    $('#top-swiper .swiper-slide').each(function (i, v) {
      if ($(this).hasClass('swiper-slide-visible')) {
        if ($(this).hasClass('slide-invisible')) {
          $(this).removeClass('slide-invisible');
        }
      } else if ($(this).hasClass('slide-invisible') === false) {
        $(this).addClass('slide-invisible');
      }
    });

    // $('#top-swiper .swiper-slide').removeClass('hoge-0 hoge-1 hoge-2 hoge-3 hoge-4');
    // $('#top-swiper .swiper-slide-visible').each(function (i, v) {
    //   $(this).addClass('hoge-' + i);
    // });
  }
  let swiper = new Swiper('#top-swiper', {
    autoplay: {
      delay: 2000,
      // disableOnInteraction: false,
    },
    loop: true,
    // speed: 0,
    // allowTouchMove: false,
    slidesPerView: 4,
    spaceBetween: 10.55,
    slidesPerGroup: 1,
    centeredSlides: true,
  });

  // let swiper = new Swiper('#top-swiper', {
  //   loop: true,
  //   speed: 0,
  //   allowTouchMove: false,
  //   autoplay: {
  //     delay: 2000,
  //     disableOnInteraction: false,
  //   },
  //   slidesPerView: 0.5,
  //   setWrapperSize: true,
  //   breakpoints: {
  //     1381: {
  //       slidesPerView: 3.5,
  //     },
  //     1025: {
  //       slidesPerView: 3.25,
  //     },
  //   },
  //   watchSlidesProgress: true,
  //   centeredSlides: true,
  //   on: {
  //     slideChange: function () {
  //       slideVisibleToggle(document.querySelectorAll('.swiper-slide'));
  //     },
  //   },
  // });

  let serviceSwiper = new Swiper('#service-swiper', {
    autoplay: {
      delay: 2000,
    },
    centeredSlides: true,
    slidesPerView: 1,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
  });

  let aboutSwiper = new Swiper('#about-swiper', {
    loop: true,
    speed: 6000,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    slidesPerView: 1.25,
    breakpoints: {
      600: {
        slidesPerView: 2.25,
      },
      1025: {
        slidesPerView: 3.25,
      },
      1381: {
        slidesPerView: 3.75,
      }
    }
  });

  $('#sp-menu').on('click', function () {
    let me = $(this);
    if (me.hasClass('active')) {
      $('.l-header-nav').css('display', 'none');
      $('#sp-menu, .l-header-inner').removeClass('active');
      $('.l-header').removeClass('sp-menu-open');
      $('.c-nav-header').addClass('c-nav-on-active');
      $('.l-header-copyright').hide();
      me.removeClass('active');
    } else {
      me.addClass('active');
      $('.l-header-nav').css('display', 'unset');
      $('#sp-menu, .l-header-inner').addClass('active');
      $('.l-header').addClass('sp-menu-open');
      $('.c-nav-header').removeClass('c-nav-on-active');
      $('.l-header-copyright').show();
    }
  });

  $('.sub-menu').prev().addClass('c-nav-item-multi-menu').append('<i class="c-icon-plus"></i>');
  $('.c-nav-item-multi-menu').on('click', function (event) {
    event.preventDefault();
    let me = $(this);
    if ($('#sp-menu').hasClass('active') && me.prev()) {
      me.children().toggleClass('c-icon-plus').toggleClass('c-icon-minus');
      me.next().toggle();
    } else {
      location.href = me.attr('href');
    }
  });

  $('.c-button-secondary').hover(
    function () {
      $(this)
        .children('i')
        .removeClass('c-icon-arrow')
        .addClass('c-icon-arrow-hover');
    },
    function () {
      $(this)
        .children('i')
        .removeClass('c-icon-arrow-hover')
        .addClass('c-icon-arrow');
    }
  );

  // IRのFAQ
  $('.c-accordion-term').on('click', function () {
    let me = $(this);
    if (me.children('i.c-icon-arrow-bottom').hasClass('c-icon-arrow-bottom')) {
      me.children('i.c-icon-arrow-bottom')
        .removeClass('c-icon-arrow-bottom')
        .addClass('c-icon-arrow-top');
    } else {
      me.children('i.c-icon-arrow-top')
        .removeClass('c-icon-arrow-top')
        .addClass('c-icon-arrow-bottom');
    }
    me.next('dd').slideToggle();
  });

  // SNS
  let snsLinks = $('.c-button-sns');
  if (snsLinks.length > 0) {
    let url = location.href;
    for (let i = 0; i < snsLinks.length; i++) {
      let href = snsLinks.eq(i).attr('href');
      href = href.replace('u=', 'u=' + url);
      href = href.replace('url=', 'url=' + url);
      snsLinks.eq(i).attr('href', href);
    }
  }

  $(window).on('load', function () {
    // 年の切り替えボタン内のテキストを仕様通りに変更
    $('#library .activeTarget a' +
        ', #security .activeTarget a' +
        ', #material .activeTarget a' +
        ', #news .s_eirAnchor .activeTarget a'
    ).each(function () {
      let me = $(this);
      me.text(me.text().slice(0, 5));
      if (me.parent().hasClass('active')) {
        // IRニュースの年切り替えタブはタイトルも追加
        $('#news .eirBlock').prepend(
          '<h2 class="c-heading-section c-badge-heading">' + me.text() + '</h2>'
        );
      }
    });

    // IRニュースのラベル変更時にタイトルも変更する
    $('#news .s_eirAnchor a, #news .s_eirTab a').on('click', function () {
      $('#news .s_eirAnchor .activeTarget a').each(function () {
        let me = $(this);
        if (me.parent().hasClass('active')) {
          $('#news .eirBlock').prepend('<h2 class="c-heading-section c-badge-heading">' + me.text() + '</h2>');
        }
      });
    });

  });
});
